<template>
  <div class="m-nav">
    <div class="nav_top">
      <h1 class="logo" @click="goHome">Free Novel</h1>
      <div class="nav_img" :class="{ bgc_g: isShow }" @click="isShow = !isShow">
        <img :src="listIcon1" />
      </div>
    </div>
    <div class="nav_bottom" v-if="isShow">
      <div class="dropdown">
        <div
          class="check_box"
          @click="novelShow"
          :class="{ bgc_active: isNovel }"
        >
          <img :src="listIcon2" class="inline_b" />
          <p class="inline_b">Novel List</p>
          <p class="more_icon inline_b"></p>
        </div>
        <ul class="dropdown-menu" v-if="isNovel">
          <li
            v-for="(item, index) in lists"
            :key="index"
            @click="goType(item.id, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="dropdown">
        <div
          class="check_box"
          @click="genreShow"
          :class="{ bgc_active: isGenre }"
        >
          <img :src="listIcon2" class="inline_b" />
          <p class="inline_b">Genre</p>
          <p class="more_icon inline_b"></p>
        </div>
        <ul class="dropdown-menu" v-if="isGenre">
          <li
            v-for="(item, index) in typeList"
            :key="index"
            @click="goTypeList(item.id, 2, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="search">
        <input
          class="form-control"
          v-model="searchValue"
          placeholder="Search..."
          @focus="onfoucs"
          @keyup.enter="goSearchList(searchValue)"
        />
        <div class="form_btn" @click="goSearchList(searchValue)">
          <img :src="search" />
        </div>
      </div>
    </div>
    <div class="nav_title" v-if="navigationBar">
      <div class="container">
        <p v-if="!navInform">
          Read Daily Updated Light Novel, Web Novel, Chinese Novel, Japanese And
          Korean Novel Online. Novelfull online, Books online free .
        </p>
        <ul class="nav_bar" v-else>
          <li @click="goHome">
            <span class="home_icon"></span><span class="hover_c">Novel</span>
          </li>
          <li v-if="url">
            <span>{{ url }}</span>
          </li>
          <li @click="onUrl" v-if="page == 'Article' || page == 'ArticleList'">
            <span class="hover_c">{{ detailUrl.name }}</span>
          </li>
          <li v-if="page == 'Article'">
            <span class="hover_c">{{ detailUrl.detailName }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fictionLabel } from "@/api/index.js";
import search from "@/assets/img/search.png";
import listIcon2 from "@/assets/img/listIcon2.png";
import listIcon1 from "@/assets/img/listIcon1.png";
export default {
  props: {
    navigationBar: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    navInform: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    page: {
      type: String,
      default: () => {
        return null;
      },
    },
    url: {
      type: String,
      default: () => {
        return "";
      },
    },
    detailUrl: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      search: search,
      listIcon2: listIcon2,
      listIcon1: listIcon1,
      isShow: false,
      isNovel: false, // 列表下拉框
      isGenre: false, // 分类下拉框
      lists: [
        { id: 1, name: "Latest Release" },
        { id: 2, name: "Hot Novel" },
        {
          id: 3,
          name: "Completed Novel",
        },
      ],
      searchValue: "", //搜索关键字
      typeList: [], //小说类型
    };
  },
  created() {
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
    }
  },
  methods: {
    // 小说分类
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          this.typeList = res.data;
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
        }
      });
    },
    // 去首页
    goHome() {
      if (this.page !== "Home") {
        this.$router.replace("/home"); //跳转的页面
      }
    },
    // 当前类型显示
    novelShow() {
      if (this.isGenre) {
        this.isGenre = false;
      }
      this.isNovel = !this.isNovel;
    },
    // 类型显示
    genreShow() {
      if (this.isNovel) {
        this.isNovel = false;
      }
      this.isGenre = !this.isGenre;
    },
    // 清楚下拉框
    clearSelect() {
      if (this.isGenre) {
        this.isGenre = false;
      }
      if (this.isNovel) {
        this.isNovel = false;
      }
      if (this.isShow) {
        this.isShow = false;
      }
    },
    // input聚焦
    onfoucs() {
      if (this.isGenre) {
        this.isGenre = false;
      }
      if (this.isNovel) {
        this.isNovel = false;
      }
    },
    // 路由跳转
    onUrl() {
      if (this.page == "ArticleList") {
        this.$router.push({
          path: `/genre/${this.detailUrl.id}`,
          query: { type: this.detailUrl.name },
        });
      } else {
        this.$router.push({
          path: `/articleList/${this.detailUrl.id}`,
        }); //跳转的页面
      }
    },
    // 去分类列表 类型5
    goType(key, name) {
      name = name.replace(/\s/g, "-");
      // if (this.urlPath.indexOf(name) == -1) {
      //   this.$router.replace(`/list/${name}`);
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onType", key, type, name);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push(`/list/${name}`);
      } else {
        this.$emit("onType", key, name);
      }
    },
    // 去小说分类列表 类型2
    goTypeList(key, type, name) {
      name = name.replace(/\s/g, "-");
      // if (this.urlPath.indexOf(name) == -1) {
      //   this.$router.replace({
      //     path: `/genre/${key}`,
      //     query: { type: name },
      //   });
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onNavType", key, type, name);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
      } else {
        this.$emit("onNavType", key, 2, name);
      }
    },
    // 去搜索列表 类型1
    goSearchList(value) {
      // if (
      //   this.urlPath.indexOf(value) == -1 &&
      //   this.urlPath.indexOf("/search") == -1
      // ) {
      //   this.$router.replace({ path: "/search", query: { keyword: value } });
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onSearch", value);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push({ path: "/search", query: { keyword: value } });
      } else {
        this.$emit("onSearch", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.m-nav {
  width: 100%;
  background-color: #008595;
  font-size: 14px;

  .nav_top {
    padding: 10px 15px 10px 20px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      font-size: 30px;
      color: #fff;
      font-weight: 900;
    }
    .nav_img {
      width: 44px;
      height: 34px;
      border: 1px solid #ddd;
      border-radius: 4px;
      position: relative;

      img {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 16px;
        width: 24px;
      }
    }
  }

  .bgc_g {
    background-color: #ddd;
  }

  .nav_bottom {
    padding: 7.5px 0;
    border-top: 1px solid #e7e7e7;
    overflow-y: auto;

    .dropdown {
      .check_box {
        height: 40px;
        padding: 10px 15px;
        color: #fff;

        img {
          width: 14px;
          margin-right: 5px;
        }

        .inline_b {
          display: inline-block;
          vertical-align: middle;
        }

        .more_icon {
          margin-top: 4px;
          margin-left: 5px;
          width: 0;
          height: 0;
          border: 4px solid transparent;
          border-top-color: #fff;
        }
      }

      .dropdown-menu {
        color: #fff;

        li {
          padding: 5px 15px 5px 25px;
        }
      }
    }

    .bgc_active {
      background-color: #0995a6;
    }

    .search {
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 10px 15px;
      color: #555;
      display: flex;

      .form-control {
        height: 32px;
        flex: 1;
        padding: 5px 10px;
        border: none;
      }

      .form_btn {
        background: #fff;
        padding: 8px 10px;

        img {
          display: block;
          height: 16px;
        }

        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }

  .nav_title {
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    background: #eaeff2;
    margin-bottom: 20px;
    color: #8d8d8d;

    .nav_bar {
      white-space: nowrap;
      overflow: hidden;
      .home_icon {
        position: relative;
        margin-right: 20px;
        &::after {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/homeIcon.png") center center no-repeat;
          background-size: 100% 100%;
          width: 18px;
          height: 18px;
        }
      }
      li {
        display: inline-block;

        &:first-child {
          color: #666;
        }

        &:first-child::before {
          display: none;
        }

        &::before {
          padding: 0 5px;
          color: #ccc;
          content: "/";
        }
      }
    }

    .container p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>