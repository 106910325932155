<template>
  <div class="nav">
    <div class="header">
      <div class="container-fluid container">
        <h1 class="logo" @click="goHome">Free Novel</h1>
        <!-- <img class="navbar-brand" :src="logoImg" @click="goHome" /> -->
        <div class="dropdown" @click.stop @click="novelShow">
          <div class="check_box" :class="{ bgc_b: isNovel }">
            <img :src="listIcon2" class="inline_b" />
            <p class="inline_b">Novel List</p>
            <p class="more_icon inline_b"></p>
          </div>
          <ul class="dropdown-menu" v-if="isNovel">
            <li
              v-for="(item, index) in lists"
              :key="index"
              @click="goType(item.id, item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="dropdown" @click.stop @click="genreShow">
          <div class="check_box" :class="{ bgc_b: isGenre }">
            <img class="inline_b" :src="listIcon2" />
            <p class="inline_b">Genre</p>
            <p class="more_icon inline_b"></p>
          </div>
          <ul class="dropdown-menu dropdown-warp" v-if="isGenre">
            <li
              v-for="item in typeList"
              :key="item.id"
              @click="goTypeList(item.id, item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>

        <div class="d-flex">
          <input
            class="form-control"
            v-model="searchValue"
            placeholder="Search..."
            @keyup.enter="goSearchList(searchValue)"
          />
          <div class="form_btn" @click="goSearchList(searchValue)">
            <img :src="search" />
          </div>
        </div>
      </div>
    </div>
    <div class="nav_title" v-if="navigationBar">
      <div class="container">
        <p v-if="!navInform">
          Read Daily Updated Light Novel, Web Novel, Chinese Novel, Japanese And
          Korean Novel Online. Novelfull online, Books online free .
        </p>
        <ul class="nav_bar" v-else>
          <li @click="goHome">
            <span class="home_icon"></span><span class="hover_c">Novel</span>
          </li>
          <li v-if="url">
            <span>{{ url }}</span>
          </li>
          <li @click="onUrl" v-if="page == 'Article' || page == 'ArticleList'">
            <span class="hover_c">{{ detailUrl.name }}</span>
          </li>
          <li v-if="page == 'Article'">
            <span class="hover_c">{{ detailUrl.detailName }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import search from "@/assets/img/search.png";
import listIcon2 from "@/assets/img/listIcon2.png";
import logoImg from "@/assets/logo.png";
export default {
  props: {
    navigationBar: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    navInform: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    page: {
      type: String,
      default: () => {
        return null;
      },
    },
    url: {
      type: String,
      default: () => {
        return "";
      },
    },
    detailUrl: {
      type: Object,
      default: () => {
        return {};
      },
    },
    typeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  inject: ["reload"],
  data() {
    return {
      search: search,
      listIcon2: listIcon2,
      logoImg: logoImg,
      isNovel: false, // 列表下拉框
      isGenre: false, // 分类下拉框
      searchValue: "", //搜索关键字
      lists: [
        { id: 1, name: "Latest Release" },
        { id: 2, name: "Hot Novel" },
        {
          id: 3,
          name: "Completed Novel",
        },
      ],
      urlPath: null, //当前路径
    };
  },
  created() {
    this.urlPath = window.location.href; //获取当前url
  },
  methods: {
    //回到首页
    goHome() {
      if (this.page !== "Home") {
        this.$router.replace("/home"); //跳转的页面
      }
    },
    // 列表下拉框
    novelShow() {
      if (this.isGenre) {
        this.isGenre = false;
      }
      this.isNovel = !this.isNovel;
    },
    // 分类下拉框
    genreShow() {
      if (this.isNovel) {
        this.isNovel = false;
      }
      this.isGenre = !this.isGenre;
    },
    // 关闭下拉框
    clearSelect() {
      if (this.isGenre) {
        this.isGenre = false;
      }
      if (this.isNovel) {
        this.isNovel = false;
      }
    },
    // 路由跳转
    onUrl() {
      if (this.page == "ArticleList") {
        this.$router.push({
          path: `/genre/${this.detailUrl.id}`,
          query: { type: this.detailUrl.name },
        });
      } else {
        this.$router.push({
          path: `/articleList/${this.detailUrl.id}`,
        }); //跳转的页面
      }
    },
    // 去分类列表 类型5
    goType(key, name) {
      name = name.replace(/\s/g, "-");
      // if (this.urlPath.indexOf(name) == -1) {
      //   this.$router.replace(`/list/${name}`);
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onType", key, type, name);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push(`/list/${name}`);
      } else {
        this.$emit("onType", key, name);
      }
    },
    // 去小说分类列表 类型2
    goTypeList(key, name) {
      name = name.replace(/\s/g, "-");
      // if (this.urlPath.indexOf(name) == -1) {
      //   this.$router.replace({
      //     path: `/genre/${key}`,
      //     query: { type: name },
      //   });
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onNavType", key, type, name);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
      } else {
        this.$emit("onNavType", key, 2, name);
      }
    },
    // 去搜索列表 类型1
    goSearchList(value) {
      // if (
      //   this.urlPath.indexOf(value) == -1 &&
      //   this.urlPath.indexOf("/search") == -1
      // ) {
      //   this.$router.replace({ path: "/search", query: { keyword: value } });
      //   if (this.page == "List") {
      //     this.reload();
      //     // this.$emit("onSearch", value);
      //   }
      // }
      if (this.page !== "List") {
        this.$router.push({ path: "/search", query: { keyword: value } });
      } else {
        this.$emit("onSearch", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  .header {
    background-color: #008595;
    color: #fff;
    width: 100%;
    .container-fluid {
      height: 56px;
      display: flex;
      position: relative;
      .logo {
        font-size: 30px;
        padding: 0 10px;
        color: #fff;
        font-weight: 900;
        line-height: 56px;
      }
      // .navbar-brand {
      //   display: block;
      //   width: 196px;
      //   height: 36px;
      //   margin-top: 10px;
      //   margin-bottom: 10px;
      // }
      .d-flex {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        color: #555;
        display: flex;
        .form-control {
          height: 32px;
          padding: 5px 10px;
          border: none;
        }
        .form_btn {
          background: #fff;
          padding: 8px 10px;
          img {
            display: block;
            height: 16px;
          }
          &:hover {
            background-color: #e6e6e6;
          }
        }
      }

      .dropdown {
        line-height: 56px;
        position: relative;
        cursor: default;
        .check_box {
          height: 53px;
          padding: 0 15px;
          img {
            width: 14px;
            margin-right: 5px;
          }
          .inline_b {
            display: inline-block;
            vertical-align: middle;
          }
          .more_icon {
            margin-top: 4px;
            margin-left: 5px;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: #fff;
          }
        }
        .dropdown-menu {
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
          list-style: none;
          padding: 5px 0;
          position: absolute;
          left: 0;
          min-width: 160px;
          z-index: 3;
          background: #0995a6;
          font-size: 15px;

          li {
            line-height: 20px;
            padding: 6px 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              background: #008595;
            }
          }
        }
        .dropdown-warp {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: space-between;
          max-height: 412px;
          width: 425px;
          li {
            max-width: 106px;
          }
        }
        .bgc_b {
          background: #0995a6;
        }
      }
    }
  }
  .nav_title {
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    background: #eaeff2;
    margin-bottom: 20px;
    color: #8d8d8d;
    .nav_bar {
      .home_icon {
        position: relative;
        margin-right: 20px;
        &::after {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/homeIcon.png") center center no-repeat;
          background-size: 100% 100%;
          width: 18px;
          height: 18px;
        }
      }
      li {
        display: inline-block;
        &:first-child {
          color: #666;
        }
        &:first-child::before {
          display: none;
        }

        &:before {
          padding: 0 5px;
          color: #ccc;
          content: "/";
        }
      }
    }
  }
}
</style>