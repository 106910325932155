<template>
  <div class="footer_box">
    <div class="container footer">
      <p>© Copyright WebNovelSite.Com. All Rights Reserved.</p>
      <p>
        <span class="a_class" @click="goPath('/contactUs')">Contact</span>
        -
        <!-- <span class="a_class" @click="goPath('/agreement')">Tos</span>- -->
        <span class="a_class">Sitemap</span>
        <span class="footer_icon" @click="goTop"><img :src="goT" /></span>
      </p>
    </div>
  </div>
</template>

<script>
import goT from "@/assets/img/goT.png";
export default {
  props: {
    isPC: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return { goT: goT };
  },
  methods: {
    goTop() {
      if (
        document.body.scrollTop !== 0 ||
        document.documentElement.scrollTop !== 0
      ) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    goPath(value) {
      let path = this.$route.path;
      if (path !== "/contactUs") {
        this.$router.push(value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_box {
  margin-top: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  .footer {
    line-height: 55px;
    display: flex;
    justify-content: space-between;
    .footer_icon {
      cursor: default;
      margin-left: 10px;
      img {
        display: inline-block;
        vertical-align: middle;
        line-height: 55px;
        border: 4px solid #4c4c4c;
        border-radius: 50%;
        height: 25px;
        width: 25px;
      }
    }
    .a_class:hover {
      cursor: default;
      text-decoration: underline;
    }
  }
}
</style>
