import request from '@/utils/request'

//小说类型
export function fictionLabel() {
    return request({
        url: '/api/novel/tag/list',
        method: 'get',
    })
}
//小说详情接口
export function fictionDetail(novel_id) {
    return request({
        url: `/api/novel/detail?novel_id=${novel_id}`,
        method: 'get'
    })
}

// 小说章节列表接口
export function fictionChapterList(page, limit, novel_id) {
    return request({
        url: `/api/novel/chapter/list?page=${page}&limit=${limit}&novel_id=${novel_id}`,
        method: 'get',
    })
}
// 获取章节内容接口
// 小说id novel_id
// 章节id chapter_id
export function fictionChapterDetail(novel_id, chapter_id) {
    return request({
        url: `/api/novel/chapter/detail?novel_id=${novel_id}&chapter_id=${chapter_id}`,
        method: 'get',
    })
}
// 搜索小说接口
// type 1搜书名 2搜标签下的小说 3搜索作者 4是否完成 
// key 书名 或者 标签id 或者作者名字
export function fictionSearch(page, limit, type, key) {
    return request({
        url: `/api/novel/search?page=${page}&limit=${limit}&type=${type}&key=${key}`,
        method: 'get',
    })
}
// 完成的小说列表接口
export function fictionCompleted(page, limit) {
    return request({
        url: `/api/novel/completed?page=${page}&limit=${limit}`,
        method: 'get',
    })
}
// 热门小说列表接口
export function fictionHot(page, limit, tagId) {
    return request({
        url: `/api/novel/hot?page=${page}&limit=${limit}&tag_id=${tagId}`,
        method: 'get',
    })
}
//最近更新的小说列表接口
export function fictionLatest(page, limit, tagId) {
    return request({
        url: `/api/novel/latest?page=${page}&limit=${limit}&tag_id=${tagId}`,
        method: 'get',
    })
}