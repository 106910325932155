<template>
  <div class="footer_box footer_m f_14">
    <span class="a_class" @click="goPath('/contactUs')">Contact</span>
    -
    <!-- <span class="a_class" @click="goPath('/agreement')">Tos</span>- -->
    <span class="a_class">Sitemap</span>
    <span class="footer_icon" @click="goTop"><img :src="goT" /></span>
  </div>
</template>
  
<script>
import goT from "@/assets/img/goT.png";
export default {
  data() {
    return { goT: goT };
  },
  methods: {
    goTop() {
      if (
        document.body.scrollTop !== 0 ||
        document.documentElement.scrollTop !== 0
      ) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    goPath(value) {
      let path = this.$route.path;
      if (path !== "/contactUs") {
        this.$router.push(value);
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.footer_box {
  line-height: 30px;
  margin-top: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  height: 30px;

  .footer_icon {
    cursor: default;
    margin-left: 10px;

    img {
      display: inline-block;
      vertical-align: middle;
      line-height: 30px;
      border: 4px solid #4c4c4c;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
  }

  .a_class {
    cursor: default;
  }
}
</style>
  