import axios from 'axios';
// import { Notify } from 'vant';
import md5 from 'js-md5';
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  timeout: 5000 // request timeout
});

// let currentTime = new Date().getTime()
let currentTime = Date.parse(new Date()) / 1000;
let appSecret = "freenovel_PUGG665c#QR52$XK*stqsNUzj88"
let sign = md5(currentTime + appSecret);
// request interceptor
service.interceptors.request.use(
  (config) => {

    // config.headers['Content-Type'] = 'application/x-www-form-urlencode'
    config.headers['SIGN'] = sign
    config.headers['TIMESTAMP'] = currentTime
    return config;
  },

  (error) => {
    console.log(error, "request"); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const {
      code,
      message,
      data,
    } = response.data;
    if (response.headers['content-disposition']) {
      return response;
    }
    // 系统异常
    if (code !== 200) {
      // Notify({ type: 'danger', message: message, duration: 5000 })

      return Promise.reject(new Error(message || 'Error'));
    }

    return response.data;
  },
  (error) => {

    const { response } = error;
    if (response) {
      const { code, message } = response.data || {};

      const err = new Error(message || 'Response Error');
      err.code = code;

      return Promise.reject(err);
    }
    // Notify({ type: 'danger', message: 'Response Error' });
    return Promise.reject(error);
  },
);

export default service;
